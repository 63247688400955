.ribbon-tabs {
  margin: 1.2rem 2.5rem 0 2.5rem;
}

.custom-home-button {
  float: right;
  margin-bottom: -32px;
  z-index: 1;
}

form div.ant-form-item-explain .ant-form-item-explain-error:not(:first-child) {
  display: none;
}

.ant-form-vertical .ant-form-item-label {
  padding-bottom: 0;
}