.dashboard-guidance-main {
  display: flex;
  padding-bottom: 2.5rem;
}

.page-header {
  display: flex;
  justify-content: space-between;
  margin: 1.2rem 2.5rem 0 2.5rem;
}

.header-label {
  font-weight: 600;
  font-size: 1.17rem;
}

.smart-card-details {
  margin: 0.625rem 0 0 2.76rem;
  display: flex;
}
.smart-card-details-opname{
  font-weight: 700;
  line-height: 1rem
}
.smart-card-details-1 {
  font-weight: 700;
  line-height: 1.5rem;
}
.smart-card-details-2 {
  font-weight: 600;
  line-height: 1.5rem;
  right: 2.5rem;
  position: absolute;
}

@media screen and (max-width: 1200px) {
  .smart-card-details {
    margin-left: 5%;
  }
  .guidance {
    min-height: 20.5625rem;
    height: fit-content;
    width: 90%;
    margin-left: 5%;
    margin-right: 5%;
  }
}

.expert-directory {
  z-index: 5;
  position: fixed;
  bottom: 5rem;
  left: 92%;
}

.tooltip {
  margin-bottom: 5rem;
  display: inline-block;
  border-bottom: 1px dotted black; /* If you want dots under the hoverable text */
}

/* Tooltip text */
.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;
  margin-left: -4rem;
  margin-top: -2.4rem;

  /* Position the tooltip text - see examples below! */
  z-index: 1;
}

.tooltip-without-border{
border-bottom:none;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext {
  visibility: visible;
}
