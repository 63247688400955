.guidance {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background: #ffffff;
  border: 0.063rem solid #f0f0f0;
  border-radius: 0.125rem;
  width: 40%;
  min-width: 33.5vw;
  min-height: 41.125rem;
  margin-left: 1rem;
  margin-right: 2.5rem;
  position: relative;
}
.guidance-heading-main {
  display: flex;
  width: 100%;
}
.guidance-heading {
  margin: 1rem !important;
  /* height: 1.5rem; */
  width: 60%;
  font-size: 1rem;
  font-weight: 600;
}
.guidance-button-main {
  margin-right: 1.5rem;
  width: 40%;
  margin-top: 0.725rem;
  text-align: end;
}

.guidance-divider {
  margin: 0;
  margin-top: 0.01rem;
  width: 100%;
}
.mo-lead-details {
  margin: 1rem;
}
.mo-lead-image {
  width: 2.5rem;
  height: 2.5rem;
  margin-right: 1rem;
}
.mo-lead-name {
  margin-bottom: 0;
  padding-bottom: 0;
  font-weight: 700;
  font-size: 0.875rem;
  line-height: 1.25rem;
  color: #007cb0;
}
.mo-lead-position {
  padding-top: 0;
  font-family: "Open Sans";
  font-size: 0.75rem;
  line-height: 1.25rem;
  color: #333333;
}
.timestamp {
  padding-left: 0.75rem;
  margin-bottom: 0;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1.25rem;
  color: #bfbfbf;
}
.guidance-body {
  width: 100%;
  margin-bottom: 6.25rem;
  padding-right: 0.5rem;
}
.mo-lead-guidance {
  font-family: "Open Sans";
  font-size: 0.875rem;
  line-height: 1.375rem;
  color: #001017;
  margin: 0 0 0 1rem;
  padding-left: 1rem;
}
.mo-lead-guidance-heading {
  font-weight: 700;
}
li {
  margin: 0 0.5rem 1.25rem 0;
  overflow-wrap: break-word;
}
.footer {
  position: absolute;
  bottom: 0;
  width: 100%;
}
.leaders {
  margin: 1rem 0 0 1rem;
  font-family: "Open Sans";
  color: rgba(0, 0, 0, 0.85);
}
.leader-content {
  margin-top: 0;
  margin-bottom: 0.25rem;
}
.leaders-heading {
  font-weight: 600;
}
.no-guidance {
  margin: auto;
}
.editor-class-read{
  border: 1px solid transparent;
}
.div-editor{
  margin-left:1.2em;
  word-break: break-word;
}