.feedback_comment_section {
  display: flex;
  justify-content: center;
  margin: 1rem;
}
.feedback_buttons {
  display: flex;
  justify-content: flex-end;
  margin-right: 1rem;
}
.feedback_label {
  display: flex;
  justify-content: center;
}
.feedback_rating {
  display: flex;
  justify-content: center;
  padding-top: 0.8rem;
}
.feedback_avatar {
  margin-right: 0.25rem;
}
.feedback_submit {
  margin-left: 0.45rem;
}
.feedback_star {
  font-size: 2rem;
}
