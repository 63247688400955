.admin-offering-input-main {
  padding: 1.2rem 0 1.2rem 0;
}

.offering-input {
  display: flex;
  flex-direction: column;
  width: 48%;
}
.admin-offering-row {
  display: flex;
  justify-content: space-between;
  padding-top: 20px;
}
.admin-offering-buttons {
  display: flex;
  justify-content: flex-end;
}
.save-button {
  margin-left: 0.5rem;
}
.delete-user-btn-row {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 0rem;
  margin-top: 1.5rem;
}

.delete-user-cancel-btn {
  display: inline-block;
  margin-right: 1.5rem;
}
