.add-user-modal-form {
    margin-top: 1.5rem;
}

.add-user-form-row{
    display: flex;
    justify-content: space-between;
}
.add-user-form-item-1{
    width: 50%;
    flex: 0 0 50%;
}
.add-user-form-item-2{
    width: 50%;
    flex: 0 0 50%;
    margin-left: 2.5rem;
}
.add-user-textfield{
    width: 80%;
}

.add-user-dropdown{
    width: 95%;
}
.add-user-form-row-2{
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-bottom: 0rem;
}

.add-user-form-table{
    width: 100%;
    margin-bottom: 1rem;
}

.add-user-form-btn-1{
    display: inline-block;
    margin-right: 1.5rem;
}

.textfield-margin {
    margin-top: 0.6rem;
}