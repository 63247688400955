.dashboard {
  width: 60%;
  margin-left: 2.5rem;
}
.dashboard-tabs-main {
  width: 100%;
}
.dashboard-getsmartstart {
  font-family: "Open Sans";
  color: #007cb0;
  font-size: 0.875rem;
  line-height: 1.375rem;
  font-weight: 400;
}
.dashboard-tabs-main .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #007cb0;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5rem;
}
.ant-tabs-top > .ant-tabs-nav .ant-tabs-ink-bar,
.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-ink-bar {
  background: #007cb0;
  display: none;
}
.ant-card-body {
  padding-top: 0.75rem;
}

.ant-tabs-tab-active {
  border-bottom: 2px solid #007cb0;
}

.custom-note {
  font-size: 13px;
  margin-top: 10px;
}