.delete-OP-btn-row {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 0rem;
  margin-top: 1.5rem;
}

.delete-OP-cancel-btn {
  display: inline-block;
  margin-right: 1.5rem;
}
