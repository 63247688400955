.chart-base-main {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.25rem;
}
.chart-base-label {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.5rem;
  /* padding-top: 8px; */
}
.chart-base-button {
  width: 5.588rem;
  height: 1.5rem;
  font-family: "Open Sans";
  font-size: 0.74rem;
  line-height: 1.375rem;
  box-shadow: none;
  text-align: center;
}
.chart-base-sub-label {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 0.813rem;
}
.ant-radio-button-checked {
  background: #007cb0;
  line-height: 1.31rem;
}
.chart-base-group {
  z-index: 0;
}
