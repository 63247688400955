.edit-role-modal-form {
  margin-top: 2.75rem;
}
.edit-role-modal-form-row {
  display: flex;
}
.edit-role-row-item-1 {
  width: 50%;
  margin-right: 1rem;
}
.edit-role-row-item-2 {
  width: 90%;
  margin-right: 1rem;
}
.edit-role-row-btn {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 0rem;
}
.edit-role-btn-1 {
  display: inline-block;
  margin-right: 1.5rem;
}
