.footer-wrapper {
    width: 100%;
    height: 1.7em;
    padding: 0 0.5em;
    font-size: 1em;
    font-family: 'Open Sans';
    display: flex;
    justify-content: end;
    align-items: center;
    gap: 0.3125em;
    background-color:white;
    position: fixed;
    bottom: 0;
    box-shadow: 0em 0em 1em -0.625em rgb(143 137 143 / 55%);
    color: #97999b;
    z-index: 1021;
  }

  .link {
    color: #0487c4;
    text-decoration : none;
  
    &:hover{
        text-decoration : underline;
        cursor : pointer;
    }
  }
  
  p{
      margin:0em;
      
  }