.upload-box {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.upload-btn {
  background-color: #007cb0 !important;
  border: none;
  color: #ffffff;
  margin-top: 5px;
  border-radius: 3px;
  width: 16rem;
  height: 2rem;
}
.file-rule {
  line-height: 2px;
  margin-top: 12px;
  margin-left: 12px;
  opacity: 0.5;
}
.file-input {
  display: inline-block;
  text-align: left;
  background: #fff;
  padding: 9px;
  width: 450px;
  position: relative;
  border-radius: 3px;
}

.file-input > [type="file"] {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: 10;
  cursor: pointer;
}

.box > .button {
  display: inline-block;
  cursor: pointer;
  background: #007cb0;
  padding: 5px 16px;
  border-radius: 2px;
  color: white;
  margin-right: 8px;
}

.box > .label {
  color: #333;
  white-space: nowrap;
  opacity: 0.3;
}

.file-input.-chosen > .label {
  opacity: 1;
}
.box {
  display: flex;
}

.tbl-filelogs{
  margin-top: 1rem;
  width:100%;
}

.admin-action-area {
  display: flex;
  justify-content: space-between;
}

.btn-reload{
  background-color: #007cb0 !important;
  border: none;
  color: #ffffff;
  border-radius: 3px;
  width: 4rem;
  height: 2rem;
  float: right;
}

.div-button-reload{
  float: right;
}

.div-buttons{
  width:75%;
}