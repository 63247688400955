.dropdown-label {
  margin-bottom: 0.5rem;
}

.dropdown-sublabel {
  padding-top: 0.3rem;
}

.dropdown {
  width: 100%;
  display: flex;
}

.unEditable-dropdown-subLabel {
  padding-top: 0.45rem;
}
