@import "../../../shared/enums/colors.css";

.help-icon {
  position: absolute;
  right: 300;
  bottom: 20;
}

.help-icon:hover {
  transform: scale(1.1);
  cursor: pointer;
}

.close-icon:hover {
  transform: scale(1.1);
  cursor: pointer;
}

.directory-modal-open {
  border-color: blue;
  border-radius: 5rem;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.directory-header {
  /* position: fixed; */
  width: 100%;
}

.directory-header-1 {
  display: flex;
  width: 100%;
  z-index: 1;
  background-color: #007cb0;
  flex-direction: row;
  padding: 0.25rem;
  justify-content: space-between;
  margin-bottom: 1rem;
}

.header-img {
  align-self: center;
  width: 18%;
  margin-left: 0.5rem;
}

.header-close-img {
  align-self: center;
  float: right;
  width: 12%;
  margin-bottom: 0.5rem;
}

.header-back-img {
  align-self: center;
  float: right;
  width: 12%;
  margin-bottom: 0.5rem;
}

.header-minimize-img {
  align-self: center;
  float: right;
  width: 12%;
  margin-bottom: 0.5rem;
}

.minimize-icon:hover {
  transform: scale(1.1);
  cursor: pointer;
}

.back-icon:hover {
  transform: scale(1.1);
  cursor: pointer;
}

.header-text {
  margin-top: 0.25rem;
  width: 82%;
}

.directory-title {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  margin-bottom: 0.25rem;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
  text-align: left;
}

.directory-subtitle {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #ffffff;
  text-align: left;
}

.directory-instructions {
  background-color: #e5f7ff;
  width: 90%;
  z-index: 1;
  margin: auto;
  align-self: center;
  height: "4rem";
  padding: 0.5rem;
  border-radius: 8px;
  margin-bottom: 1rem;
}

.directory-instructions-greeting {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: #001017;
  text-align: center;
}

.directory-instructions-question {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #001017;
  text-align: center;
}

.directory-contacts {
  display: flex;
  flex-direction: column;
  /* justify-content: space-evenly; */
  width: 100%;
  padding: 1rem;
  height: fit-content;
  max-height: 22rem;
  overflow-y: scroll;
  overflow-x: hidden;
}

.error {
  color: red;
  display: inline-block;
}

.list-buttons {
  padding: 0.25rem;
  /* min-width: 100%;
  width: 14.625rem; */
}
.expert-directory-options-btn {
  word-wrap: break-word;
  white-space: normal;
}

@media screen and (max-width: 900px) {
  .list-buttons {
    width: 80%;
  }
  .expert-directory-options-btn {
    width: 100% !important;
    font-size: smaller;
  }
  .directory-contacts {
    overflow-y: scroll !important;
    overflow-x: hidden;
  }
  .directory-title {
    font-size: small;
    padding-left: 8px;
  }
  .directory-subtitle {
    font-size: small;
    padding-left: 8px;
  }

  .header-img {
    display: none;
  }
}

.directory-topic-contacts {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: #001017;
  text-align: center;
}

.contacts-main {
  width: 100%;
  font-family: "Open Sans";
  font-style: normal;
  text-align: center;
  line-height: 20px;
  border: 2px solid LIGHTBLUE;
  border-radius: 20px;
  padding: 15px;
}

.contacts-name {
  font-weight: 700;
  font-size: 16px;
  color: #001017;
  line-height: 30px;
}

.expert-directory-options-btn:hover {
  background-color: #007cb0 !important;
  color: white !important;
}

.expert-directory-options-btn-selected {
  background-color: #007cb0 !important;
  color: white !important;
}

.area-listings {
  /* margin: auto; */
  align-self: center;
  word-wrap: normal;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.topic-listings {
  margin: auto;
  align-self: center;
  word-wrap: normal;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.expert-directory-modal {
  min-height: 15rem;
  padding: 0px;
  width: 100%;
}
