.market-offering-pagination {
  display: flex;
  justify-content: end;
  padding-top: 10px;
}
.main {
  display: flex;
  justify-content: space-between;
}
.mo-admin-header {
  display: flex;
  align-items: center;
  margin-bottom: 2rem;
  /* margin-bottom: 20px; */
}

.mo-admin-header-search {
  display: flex;
}

.mo-admin-header-search-textfield {
  margin-right: 0.5rem;
}

.mo-admin-header-filter {
  margin-right: 0.5rem;
  display: flex;
  align-items: center;
  width: 10rem;
}

.mo-admin-action-area {
  display: flex;
  justify-content: space-between;
}

.ant-table-cell{
  white-space: pre-wrap;
}