.custom-button {
    background-color: #00364d;
    border-color: #00364d;
    margin-top: 17px;
    width: 25px;
}

.custom-button:hover, .custom-button:focus, .custom-button:active {
    background-color: #00364d;
    border-color: #00364d;
}