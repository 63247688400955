.user-main {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}
.label {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
}

.ant-table-thead .ant-table-cell {
  font-family: "Open Sans";
  font-style: normal;
  font-size: 14px !important;
  line-height: 22px;
  font-weight: 600;
  background-color: #d9d9d9;
}
.user-paginantion {
  display: flex;
  justify-content: end;
  padding-top: 10px;
}
.admin-action-area {
  display: flex;
  justify-content: space-between;
}
.search {
  display: flex;
}
.ant-pagination-options {
  display: none !important;
}
.label {
  display: flex;
  align-items: center;
}
.filter-rating {
  display: flex;
  align-items: center;
  width: 13rem;
}
.ant-pagination-options {
  display: none !important;
}
.filter-span {
  display: flex;
  align-items: center;
  margin-right: 5px;
}
.filter-label {
  margin-left: 5px;
  font-weight: 600;
  font-size: 15px;
}
