.chart-data {
  position: relative;
}
.chart-no-data {
  position: absolute;
  left: 20.6%;
  right: 20.43%;
  top: 27.66%;
  bottom: 5.59%;
}
