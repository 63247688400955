.admin-offering-input-main {
  padding: 1.2rem 0 1.2rem 0;
}

.admin-market-offering-row {
  display: grid;
  justify-content: space-evenly;
  padding-top: 20px;
  grid-template-columns: 33% 33% 33%;
}

.market-offering-input {
  flex-direction: column;
  width: 90%;
  margin-right: 1rem;
  grid-row: 1/3;
}

.mo_admin-dropdown {
  width: 100%;
}

.mo_admin-add-dropdown {
  margin-top: -0.5rem;
  overflow: hidden;
}

.market-offering-text-heading {
  flex-direction: column;
  margin-right: 1rem;
  grid-column: 1/3;
  font-weight: bold;
}

.market-offering-text-heading-2 {
  flex-direction: column;
  margin-right: 1rem;
  grid-column: 1/3;
  font-weight: bold;
}

.market-offering-input-nsr {
  flex-direction: column;
  margin-right: 1rem;
  grid-row-gap: 1rem;
  grid-column: 1/3;
  display: grid;
  grid-template-columns: 43% 10% 43%;
}

.nsr-input {
  flex-direction: column;
  margin-right: 1rem;
  grid-column: 1/2;
}
.nsr-input-text {
  flex-direction: column;
  margin-left: 1rem;
  justify-content: center;
  width: fit-content;
  grid-column: 2/3;
  margin-top: 1rem;
  align-self: center;
}

.nsr-input-to {
  flex-direction: column;
  margin-right: 1rem;
  grid-column: 3/6;
  margin-top: auto;
  align-self: center;
}

.nsr-input-text-for-large {
  flex-direction: column;
  justify-content: center;
  width: fit-content;
  grid-column: 2/4;
  margin-top: 1rem;
  margin-left: 1rem;
  align-self: center;
}

.admin-market-offering-textarea {
  display: grid;
  padding-top: 20px;
  grid-template-columns: 63% 3.5%;
}

.market-offering-input-textarea {
  flex-direction: column;
  width: 100%;
  margin-right: 1rem;
  grid-row: 1/3;
}

.admin-offering-buttons {
  display: flex;
  justify-content: flex-end;
}
.save-button {
  margin-left: 0.5rem;
}
.delete-user-btn-row {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 0rem;
  margin-top: 1.5rem;
}

.delete-user-cancel-btn {
  display: inline-block;
  margin-right: 1.5rem;
}
