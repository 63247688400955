@import "../../../shared/enums/colors.css";

.modal-title{
    width: 60%;
    margin: 0;
    color: rgba(0, 0, 0, 0.85);
    padding-left: 0 !important;
    font-weight: 600;
    font-size: 1.1rem;
}
.modal-text{
    width: 75%;
    height: 2.5rem;
    border: 1px solid var(--inputBorder);
}

.modal-header{
    display: flex;
    width: 100%;
    margin-bottom: 1.6rem;
}

.skip-button{
    width: 40%;
    text-align: end;
}

.search-button{
    width: 25%;
    text-align: end;
}

.input-class{
    display: flex;
    width: 100%;
}
.ant-modal-close-x{
    width: 9.375rem;
    margin-right: 2rem;
    padding-top: 0.5rem;
}
.ant-modal-title{
    margin-top: 0.5rem;
    font-weight: 600;
    font-size: 1.1rem;
}
.suggestion-labels{
    display: flex;
}
.labels{
    width: 10.735rem;
    background-color: var(--labelBackground);
    border: 1px solid var(--labelBorder);
    padding: 0.1rem 0.5rem;
    margin: 0.5rem 0.5rem 0 0;
}
.error{
    color: red;
    display: inline-block;
}


