.site-card {
  padding: 2rem 2rem 1.5rem 2.5rem;
  width: 100%;
}

.ant-card-body {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  padding-bottom: 2rem;
}

.dropdown-div {
  margin-right: 5.375rem;
  width: 100%;
}
.dropdown-div:last-child {
  margin-right: 0;
  width: 100%;
}

.ant-space {
  width: 100%;
  justify-content: space-between;
}
@media screen and (max-width: 1200px) {
  .ant-card-body {
    flex-wrap: wrap;
  }
  .dropdown-div {
    margin-right: 1rem !important;
    margin-top: 0.5rem;
  }
  .site-card {
    padding: 2rem 5% 1.5rem 5% !important;
  }
}
