.add-guidance-modal-form {
  margin-left: 2.5rem;
  margin-right: 1.875rem;
}
.form-row-1 {
  display: flex;
  justify-content: space-between;
}
.form-item-1 {
  width: 50%;
  flex: 0 0 50%;
}
.form-item-2 {
  width: 50%;
  flex: 0 0 50%;
  margin-left: 2.5rem;
}
.textfield {
  width: 90%;
}

.form-item-3 {
  width: 100%;
}
.form-item-4 {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 0rem;
  margin-top: 3rem;
}

.form-item-4-btn {
  display: inline-block;
  margin-right: 1.5rem;
}
.modal-title {
  font-weight: 600;
  font-size: 1rem;
  height: 1.5rem;
  padding-left: 1.5rem;
}

.wrapper-class {
  padding-bottom: 1rem;
  height:13em;
}

.editor-class {
  padding: 1rem;
  border: 1px solid #ccc;
  height: 13em;
}

.toolbar-class {
  border: 1px solid #ccc;
}

.rdw-inline-wrapper > .rdw-option-active {
  background-color: #d9d9d9;
}

.rdw-list-wrapper > .rdw-option-active {
  background-color: #d9d9d9;
}
.ql-editor{
    min-height:120px !important;
}

.quill > .ql-container > .ql-editor.ql-blank::before{
  font-family: 'Open Sans';
  font-style: normal;
  font-size: 14px;
}