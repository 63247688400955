.chart-dropdown-container {
  height: 62px;
  display: flex;
  justify-content: space-around;
  vertical-align: middle;
}

.chart-dropdown {
  height: 62px;
  margin-left: 1em;
  top: 488px;
  display: flex;
  justify-content: space-between;
}

.chart-dropdown-timeframe {
  width: 8.7rem;
  height: 32px;
  font-size: small;
}

.empty-chart-dropdown-timeframe{
  width: 9rem;
  height: 32px;
  font-size: small;
}

.chart-dropdown-highlight {
  width: 10rem;
  height: 32px;
  font-size: small;
}

.chart-dropdown-nsr {
  width: 7rem;
  height: 32px;
  font-size: small;
}

.chart-dropdown .dropdown {
  font-size: small;
  height: 1.5rem;
  align-items: center;
}

.dropdown-xaxis {
  display: flex;
  justify-content: center;
  padding-left: 2.5rem;
  margin-top: 0rem;
}
.chart-legends {
  display: flex;
  margin-top: -34px;
  font-size: 0.75rem;
  /* padding-right: 0.5rem; */
  justify-content: flex-end;
}
.chart-legend-box {
  display: flex;
  margin-top: auto;
  margin: 0.5rem;
  align-items: center;
}
.color-box {
  height: 0.875rem;
  width: 0.875rem;
  margin-right: 0.4rem;
}

@media screen and (max-width: 1400px) and (min-width: 1201px) {
  .chart-legends {
    margin-top: -10px;
  }
  .chart-legend-box {
    margin-right: 0.4rem;
  }
}

@media screen and (max-width: 900px) {
  .chart-legends {
    margin-top: -10px;
  }
  .chart-legend-box {
    margin-right: 0.4rem;
  }
}

.empty-chart-dropdown {
  width: 27rem;
}
.chart-dropdown-one {
  display: flex;
  width: 28rem;
  justify-content: space-evenly;
}
